.contact-section {
    padding: 20px;
    max-width: 800px;
    margin: auto;
    text-align: center;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.contact-section h1 {
    font-size: 2.5em;
    margin-bottom: 20px;
    color: #007BFF;
    transition: color 0.3s ease;
}

.contact-section h1:hover {
    color: #0056b3;
}

.contact-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.contact-form {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 20px;
    background: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.contact-illustration {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.contact-illustration img {
    max-width: 100%;
    height: auto;
    border-radius: 10px;
    transition: transform 0.3s ease;
}

.contact-illustration img:hover {
    transform: scale(1.05);
}

.input-container {
    position: relative;
}

.input-container label {
    display: block;
    margin-bottom: 5px;
    text-align: left;
    font-weight: bold;
}

.input-icon {
    position: relative;
}

.input-icon i {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    color: #aaa;
    background-color: white;
    padding: 0 5px;
    z-index: 1;
}

.input-icon input,
.input-icon textarea {
    padding: 10px 10px 10px 40px;
    font-size: 1em;
    border: 1px solid #ccc;
    border-radius: 10px;
    width: 100%;
    transition: border-color 0.3s ease;
}

.input-icon textarea {
    height: 100px;
    resize: none;
}


.input-icon .fa-comment {
    top: 15px;
    transform: none;
}

.contact-form input,
.contact-form textarea {
    padding: 10px;
    font-size: 1em;
    border: 1px solid #ccc;
    border-radius: 10px;
    width: 100%;
    transition: border-color 0.3s ease;
}

.contact-form input:focus,
.contact-form textarea:focus {
    border-color: #007BFF;
    outline: none;
}

.contact-form textarea {
    height: 100px;
    resize: none;
}

.submit-button {
    padding: 10px 20px;
    font-size: 1em;
    border: none;
    border-radius: 10px;
    background-color: #007BFF;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.submit-button:hover {
    background-color: #0056b3;
}

.feedback-message {
    margin-top: 10px;
    font-size: 1em;
}

.feedback-message.success {
    color: green;
}

.feedback-message.error {
    color: red;
}

.input-icon textarea {
    padding-left: 10px; /* Reset padding for textarea */
}

.input-icon .fa-comment {
    top: 15px; /* Adjust the icon position for the textarea */
    transform: none;
}

.input-icon {
    position: relative;
}

.input-icon i {
    position: absolute;
    left: 10px;
    top: 12px; /* Adjust this value as needed */
    color: #aaa;
    pointer-events: none;
}

.input-icon textarea {
    padding-left: 35px;
    padding-top: 10px;
}