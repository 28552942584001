/* Staff Page Styles */
.staff-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center; /* Aligns text to the center */
  margin: 40px auto; /* Centers the section and adds vertical spacing */
  max-width: 1200px; /* Sets a maximum width for the staff section */
}

.staff-section h1 {
  text-decoration: underline;
  margin: 20px 0;
  width: 100%;
  font-size: 2.5rem;
}

.staff-profile {
  width: 80%;
  margin: auto;
  display: flex;
  align-items: start;
  justify-content: space-between;
  padding: 20px 0; /* Adds padding between profiles */
  border-bottom: 1px solid #ccc; /* Adds a subtle line between staff profiles */
}

.staff-info {
  flex: 3;
  padding-right: 20px;
}

.staff-image {
  border-radius: 50%;
  width: 160px;
  height: 160px;
  object-fit: cover;
}

/* Add this class for adjustable dimensions */
.adjustable {
  max-width: 250px; /* You can adjust this value as needed */
  height: auto; /* Maintain aspect ratio */
}

/* Responsive adjustments for Staff Page */
@media (max-width: 768px) {
  .staff-profile {
    flex-direction: column; /* Stacks elements vertically */
    align-items: center; /* Centers the items in the column */
    text-align: center; /* Ensures text is centered in each profile */
    margin-bottom: 40px; /* Adds more space between profiles */
  }

  .staff-info {
    order: 2; /* Ensures text comes after the image when stacked */
    padding: 0 5%; /* Adds padding on the sides for better readability */
    width: 90%; /* Adjusts width to use more screen space */
  }

  .staff-image {
    order: 1; /* Image appears above the text */
    width: 80%; /* Increases the width of the image to make it more prominent */
    margin-bottom: 20px; /* Adds space between the image and the text */
    max-width: 300px; /* Ensures the image does not become too large */
  }

  h2, h3, p {
    margin: 10px 0; /* Adjusts margin to reduce space between text elements */
  }

  h1 {
    font-size: 2rem; /* Slightly reduces the size of the main header */
  }
}

/* Ensure text does not overflow */
p {
  word-wrap: break-word; /* Allows long words to be broken and wrapped to the next line */
}
