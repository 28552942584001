:root {
  --primary-color: #2c3e50;
  --secondary-color: #ecf0f1;
  --accent-color: #e74c3c;
  --font-primary: '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif';
}

body, html {
  margin: 0;
  padding: 0;
  font-family: var(--font-primary);
  background: var(--secondary-color);
  color: #2c3e50;
  line-height: 1.6;
}

.App {
  text-align: center;
}

main {
  padding: 2rem;
  text-align: left;
  max-width: 1200px;
  margin: auto;
  background-color: white;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
}

h1 {
  color: var(--accent-color);
}

.card-link {
  text-decoration: none;
  color: inherit;
}

.clickable-card {
  height: 250px;
  display: flex;
  flex-direction: column;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  overflow: hidden;
}

.clickable-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.card-image {
  width: 100%;
  height: 120px;
  object-fit: cover;
  object-position: center;
}

.card-content {
  flex-grow: 1;
  padding: 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.card-content h6 {
  margin-bottom: 8px;
  font-size: 1rem;
}

.card-content p {
  font-size: 0.8rem;
  line-height: 1.2;
}

.sign-up-button-container {
  display: flex;
  justify-content: center;
  margin-top: -115px;
}

.sign-up-button {
  padding: 10px 20px;
  font-size: 1em;
  border: none;
  border-radius: 10px;
  background-color: #007BFF;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.sign-up-button:hover {
  background-color: #0056b3;
}
