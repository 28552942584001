/* Existing styles */
.signup-form-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    animation: fadeIn 0.5s;
}

.signup-form-container {
    background: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    animation: slideUp 0.5s;
}

.thank-you-message {
    text-align: center;
    font-size: 1.5em;
    color: green;
    animation: fadeOut 3s 3s forwards;
}

.thank-you-message i {
    font-size: 3em;
    margin-bottom: 10px;
}

@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}

@keyframes slideUp {
    from { transform: translateY(100px); opacity: 0; }
    to { transform: translateY(0); opacity: 1; }
}

@keyframes fadeOut {
    from { opacity: 1; }
    to { opacity: 0; }
}

.input-icon {
    position: relative;
    display: flex;
}

.input-icon i {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
    z-index: 1;
}

.input-icon input {
    padding-left: 40px;
}

.submit-button {
    position: relative;
    padding: 10px 20px;
    font-size: 1em;
    border: none;
    border-radius: 10px;
    background-color: #007BFF;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s ease;
    display: flex;
    justify-content: center;
    align-items: center;
}

.submit-button:hover {
    background-color: #0056b3;
}

.spinner-icon {
    width: 24px;
    height: 24px;
    border: 3px solid rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    border-top-color: white;
    animation: spin 1s ease infinite;
}

@keyframes spin {
    to { transform: rotate(360deg); }
}

.thank-you-message.fade-out {
    animation: fadeOut 5s forwards;
}

.input-container {
    margin-bottom: 15px;
}

.input-icon input[name="event"] {
    width: 100%;
    white-space: normal;
    overflow: visible;
    text-overflow: clip;
    padding-right: 10px;
}

.input-icon input[name="event"]:read-only {
    background-color: #f0f0f0;
    cursor: default;
}