.home-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 90vh;
  background-color: var(--secondary-color);
  color: var(--primary-color);
}

.logo-container {
  width: 20rem;
  height: auto;
  margin-top: 2rem;
}

.logo {
  max-width: 100%;
  height: auto;
  transition: transform 0.3s ease;
}

.logo:hover {
  transform: scale(1.1);
}

.home-main h1 {
  font-size: 2.5rem;
  color: var(--accent-color);
  margin-bottom: 0.5rem;
}

.home-main p {
  font-size: 1.4rem;
  max-width: 600px;
  text-align: center;
  margin: auto;
}

/* Testimonial Section */
.testimonial-section {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  position: relative;
  margin-top: 2rem;
}

.speaking-girl {
  width: 150px;
  position: relative;
  margin-right: -10px;
  margin-top: 150px; /* GIF vertical adjustment */
}

.testimonial-box {
  background: linear-gradient(135deg, #cfd9df 0%, #e2ebf0 100%);
  color: #2c3e50;
  padding: 1.5rem;
  border-radius: 20px;
  position: relative;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  text-align: left;
  margin-top: 10px; /* text box up/down */
}

.testimonial-box:before {
  content: "";
  position: absolute;
  top: 100%;
  left: 20px;
  border-width: 10px 10px 0;
  border-style: solid;
  border-color: #cfd9df transparent transparent transparent;
}



